import request from "@/utils/request"
/**
 * 获取record列表
 * @param {*} params 
 * @returns 
 */
export const getList = function (params) {
  return request({
    url: "SimpleExcel/excelList",
    method: "get",
    params
  });
}

//弃用
export const changeSatus = function (params) {
  return request({
    url: "/SimpleExcel/exStatus",
    method: "get",
    params
  });
}
/**
 * 删除record Excel
 * @param {} params 
 * @returns 
 */
export const delExcel = function (params) {
  return request({
    url: "/SimpleExcel/delExcel",
    method: "get",
    params
  });
}
/**
 * 
 * @returns 获取recoder列表
 */
export const reList = function () {
  return request({
    url: "/SimpleFinalExcel/excels",
    method: "get",
  })
}