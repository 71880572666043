vue
<template>
  <div class="recoder-upload-model">
    <el-dialog :visible.sync="dialogVisible" class="dialog" title="上传记录本模板" :show-close="true">
      <div class="recoder-item">
        <div class="r-upload-box">
          <el-upload class="upload-demo" drag :action="uploadUrl" :auto-upload="false" :multiple="false" :limit="1"
            accept=".xlsx" :headers="headers" :file-list="fileList" ref="recordUpload" :on-success="uploadSuccess"
            :on-exceed="exceed" :on-error="onError">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" style="text-align: center;">将文件拖到此处，或<em>点击上传</em><br />
              只能上传xlsx文件</div>
          </el-upload>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="upload">上传</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _ from "loadsh"
export default {
  data() {
    return {
      dialogVisible: true, // 对话框可见性
      uploadUrl: "/api/CreateExcel/uploadExcel",
      fileList: []
    }
  },
  props: {
    destroy: {
      type: Function,
    },
    token: {
      type: String,
    },
    cb: {
      type: Function
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        if (this.cb && typeof this.cb == "function") {
          this.cb();
        }
        this.destroy?.();
      }
    },
  },
  computed: {
    headers: function () {
      return {
        Authorization: "Bearer " + this.token,
      }
    }
  },
  methods: {
    uploadSuccess(event) {
      if (event.code === 200) {
        this.$modal.msg("文件上传成功");
      } else {
        this.$modal.msgError(event.msg);
      }
    },
    onError(err) {
      this.$modal.msgError(err);
    },
    exceed() {
      this.$modal.msgWarning("单次只允许上传一个文件");
    },
    upload() {
      this.$refs.recordUpload.submit();
    }
  },
  created() { },
};
</script>
<style lang="less" scoped>
.dialog {
  width: 1200px;
  margin: auto;

  .recoder-item {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>